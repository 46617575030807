<template>
  <div>
    <b-container class="white-card">
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0384.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/img2.jpg" alt="Ny lackerade köksluckor"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0605.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0386.jpg" alt="Lackade flygplans detaljer"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/59914503710__F48764E9-E946-402B-9A8A-DC94CFDA25B6.jpg" alt="Lackade barstolar"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0371.jpg"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0388.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0391.jpg"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/IMG_0001.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/kök1.jpg"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/nivida.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/nivida-table.jpg"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md>
          <b-img fluid src="@/assets/kök2.jpg"></b-img>
        </b-col>
        <b-col md>
          <b-img fluid src="@/assets/kök3.jpg"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
export default {
  name: 'GalleryView',
  components: {
    BImg
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  margin-bottom: 20px;
}
</style>
